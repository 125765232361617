<!--
 * @Author: your name
 * @Date: 2021-05-11 22:11:15
 * @LastEditTime: 2021-05-20 03:42:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\skillmsg\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation>
      <!-- <div @click="deleskill" class="msgclass">
        <van-icon color="#F44C06" size=".39rem" name="delete" />
        <div>删除</div>
      </div> -->
    </navigation>
    <div class="theallmsg">
      <div class="topmsg">
        <div>
          {{
            $route.query.skillmenu == "自定义技能"
              ? allmsg.position_name
              : allmsg.skill_name
          }}
        </div>
        <van-icon
          v-if="rkarray.length > 0"
          size=".55rem"
          color="#4DBA21"
          name="passed"
        />
      </div>
      <div class="ticla">
        <div>{{ $route.query.skillmenu }}</div>
        <div>{{ allmsg.skill_type == 1||allmsg.skill_type==2 ? "平台默认技能" : "自我添加" }}</div>
        <div v-if="rkarray.length > 0">认可.{{ rkarray.length }}</div>
        <div v-else>未认可</div>
      </div>
      <div class="hengclass"></div>
      <div class="tjr">添加人</div>
      <div class="tjrmsgclass gs2">
        <img :src="allmsg.headimg_url?allmsg.headimg_url:theimg" alt="" />
        <div class="gscla">
          <div>
            <div>{{ allmsg.username }}</div>
            <div>{{allmsg.mim_id!=0?'企业':'个人'}}</div>
          </div>
          <div>{{ allmsg.company_name }}</div>
        </div>
      </div>
      <div class="tjr">认可人</div>
      <div
        v-for="(item, index) in rkarray"
        v-bind:key="index"
        class="tjrmsgclass"
      >
        <img src="../../assets/user_1.png" alt="" />
        <div class="gscla">
          <div>{{ item.realname }}</div>
          <div>{{ item.company_name }}</div>
        </div>
      </div>
    </div>
    <div class="nomsgclass"></div>
    <div class="dlclass" @click="dlmeth"><div>点亮此技能</div></div>
  </div>
</template>
<script>
export default {
  created() {
    this.allmsg = JSON.parse(this.$route.query.msg);
     
    this.getrenke();
  },
  data() {
    return {
      allmsg: "",
      rkarray: [], //认可人
      theimg:require("../../assets/user_1.png")
    };
  },
  methods: {
    //删除技能
    deleskill() {
      this.$http.post("/firm/v1/Skillme/delete", {
        reqType: "userinfo",
        id: this.allmsg.id,
        type: this.allmsg.skill_type == 1||this.allmsg.skill_type==2?'1':'2',
      })
      .then(res=>{
         
        this.$toast("删除成功");
        this.$router.go(-1);
      })
    },
    //点亮技能
    dlmeth() {
      if (this.$route.query.skillmenu == "自定义技能") {
        this.$http
          .post("/firm/v1/Skillme/positionAccept", {
            reqType: "userinfo",
            skill_id: this.allmsg.id,
            accept_user_id: localStorage.getItem("userId"),
            status: "1",
            position_name: this.allmsg.position_name,
            type: "2",
          })
          .then((res) => {
             
            this.$toast("已点亮");
            this.getrenke();
          });
      } else {
        this.$http
          .post("/firm/v1/Skillme/positionAccept", {
            reqType: "userinfo",
            skill_id: this.allmsg.id,
            accept_user_id: localStorage.getItem("userId"),
            status: "1",
            position_name: this.allmsg.skill_name,
            type: "1",
          })
          .then((res) => {
             
            this.$toast("已点亮");
            this.getrenke();
          });
      }
    },
    getrenke() {
      this.$http
        .post("/firm/v1/Skillme/acceptList", {
          reqType: "userinfo",
          skill_id: this.allmsg.id,
          type: this.allmsg.skill_type == 1||this.allmsg.skill_type==2 ? "1" : "2",
        })
        .then((res) => {
           
          this.rkarray = JSON.parse(res.data).data;
        });
    },
  },
};
</script>
<style scoped>
.nomsgclass {
  height: 1.5rem;
}
.dlclass {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 0.2rem 0;
}
.dlclass > div {
  border: 2px solid #ff5f19;
  font-size: 0.3rem;
  line-height: 0.42rem;
  padding: 0.28rem;
  text-align: center;
  margin: 0 0.31rem;
  color: #ff5f19;
  border-radius: 0.16rem;
}
.gs2 {
  margin-bottom: 1.2rem;
}
.tjrmsgclass {
  display: flex;
  align-items: center;
}
.tjrmsgclass img {
  width: 1.05rem;
  height: 1.05rem;
  border-radius: 50%;
}
.gscla {
  font-weight: bold;
  margin-left: 0.37rem;
  padding: 0.38rem 0;
  border-bottom-color: #bfbfbf;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
}
.gscla > :first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
  margin-bottom: 0.05rem;
  display: flex;
  align-items: center;
}
.gscla>:first-child>:first-child{
  margin-right: .2rem;
}
.gscla>:first-child>:nth-child(2){
  background-color: #00beff;
  color: white;
  font-size: .2rem;
  padding: 0 .15rem;
  border-radius: .1rem;
  line-height: .35rem;
}
.gscla > :nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.48rem;
  color: #979797;
}
.tjr {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
  margin-top: 0.6rem;
}
.hengclass {
  height: 1px;
  background-color: #bfbfbf;
}
.ticla {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.35rem;
  margin-bottom: 0.6rem;
}
.ticla > div {
  padding: 0.2rem 0.3rem;
  border: 1px solid #ff5f19;
  background-color: #ffede4;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #ff5f19;
  border-radius: 0.4rem;
}
.theallmsg {
  margin: 0.3rem 0.48rem;
}
.topmsg {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 0.42rem;
  line-height: 0.48rem;
  font-weight: bold;
}
.topmsg > :first-child {
  margin-right: 0.25rem;
}
.msgclass {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.msgclass > :first-child {
  margin-right: 0.14rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
</style>
